import styles from './Loading.module.scss';
import React from 'react';
import { CircularProgress } from "@mui/material";

function Loading() {
    return (
        <div
            className={styles.wrapper}
        >
            <div
                className={styles.spinner}
            >
                <CircularProgress />
            </div>
            <span>Loading current status</span>
        </div>
    );

}


export default Loading;
