import styles from './Header.module.scss';
import headerLogo from './header.png';
import React from 'react';

function Header() {
    return (
        <div className={styles.wrapper}>
            <img src={headerLogo} alt="CloudCall status page logo" />
        </div>
    );
}


export default Header;
