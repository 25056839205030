import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IncidentDetails from "./IncidentDetails";
import statusMap from "../StatusMap";
import rowStyles from "./IncidentRow.module.scss";
import styles from "./AccordionRow.module.scss";
import IncidentIcon from "../StatusIcon";

function AccordionRow(incident, id) {
    return (
        <div key={`Incident-${id}`} className={`${styles.incidentDetailsAccordion} ${rowStyles.incidentDetails} ${rowStyles[`incidentDetails-${incident.impact}`]}`}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={`${rowStyles.incidentHeader} ${styles.incidentHeader}`}
                >
                    <IncidentIcon style={incident.impact} size={30} plain={true} />
                    <Typography>{statusMap.getShortStatus(incident.impact)} - {incident.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <IncidentDetails incident={incident} />
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

function MultipleIncidentCarousel({ incidents }) {
    const incidentsDisplay = incidents.map(AccordionRow)
    return (
        <div className={styles.wrapper} data-test-id="accordion">
            {incidentsDisplay}
        </div>
    );
}
export default MultipleIncidentCarousel;
