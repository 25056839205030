import styles from './Banner.module.scss';
import React from 'react';

function Banner({message, alertStyle}) {
    const dynamicClass = styles[`banner-${alertStyle}`];
    return (
        <div className={styles.wrapper}>
            <div className={`${dynamicClass} ${styles.banner}`} data-test-id="message">
                {message}
            </div>
        </div>
    );
}


export default Banner;
