import { StatusIcon } from "../"
import React from 'react';
import styles from './ComponentGrid.module.scss';
function ComponentRow(component, index) {
    const hasValid = (component.regions || {}).emea || (component.regions || {}).americas || (component.regions || {}).apac;

    if (hasValid === undefined || !component ||!component.regions) {
        return null;
    }
    return (
        <tr key={`componentRow-${index}`} className={styles.componentRow}>
            <td className={styles.componentName}>{component.name}</td>
            <td className={styles.componentStatus}><StatusIcon style={((component.regions.emea) || {}).status} /></td>
            <td className={styles.componentStatus}><StatusIcon style={((component.regions.americas) || {}).status} /></td>
            <td className={styles.componentStatus}><StatusIcon style={((component.regions.apac) || {}).status} /></td>
        </tr>
    )
}
function ComponentGrid({components}) {
    const componentRows = components.map(ComponentRow);
    return (
        <div className={styles.wrapper}>
            <table className={styles.componentTable}>
                <thead>
                    <tr className={styles.componentRow}>
                        <th className={styles.componentTableBlank}></th>
                        <th className={styles.componentTableHeader}>EMEA</th>
                        <th className={styles.componentTableHeader}>Americas</th>
                        <th className={styles.componentTableHeader}>Asia Pacific</th>
                    </tr>
                </thead>
                <tbody>
                    { componentRows }
                </tbody>
            </table>
        </div>
    );
}


export default ComponentGrid;
