import styles from './IncidentDetails.module.scss';
import React from 'react';
import {DateTime} from "luxon";
import statusMap from "../StatusMap";
function incidentUpdate({body, updated_at, status}, index) {
    const updated = new DateTime.fromISO(updated_at);
    const displayDate = updated.toFormat('LLL d');
    const displayTime = updated.toLocaleString(DateTime.TIME_24_WITH_SHORT_OFFSET);
    return (
        <div key={index} className={styles.update}>
            {status &&

                <span className={styles.title}>
                    {statusMap.getStatus(status)}: &nbsp;
                </span>
            }
            {body}
            <div className={styles.footer}>
                Posted: { displayDate }, { displayTime }
            </div>
        </div>
    )
}

function IncidentDetails({incident}) {

    const end = new DateTime.fromISO(incident.scheduled_until);
    const endDisplayDate = end.toFormat('LLL d');
    const endDisplayTime = end.toLocaleString(DateTime.TIME_24_WITH_SHORT_OFFSET);
    const displayRegions = incident.affectedRegions.map((region) => {
        return statusMap.getStatus(region);
    }).join(', ');
    const displayComponents = incident.affectedComponents.join(', ');
    return (
        <div className={styles.body}>
            <div className={styles.componentDetails}>
                <div>
                    <div className={styles.title}>Customers Effected</div>
                    <div>{displayRegions}</div>
                </div>
                <div>
                    <div className={styles.title}>Components</div>
                    <div>{displayComponents}</div>
                </div>
            </div>
            <div className={styles.detailsInner}>
                {incident.updates.map(incidentUpdate)}
                {incident.scheduled_until &&
                    <div className={styles.footer}>
                        Expected End: {endDisplayDate}, {endDisplayTime}
                    </div>
                }
            </div>
        </div>
    );
}


export default IncidentDetails;
