import styles from './About.module.scss';
import React from 'react';

function About() {
    return (
        <div className={styles.wrapper}>
            <h1>About this site</h1>
            <span className={styles.body}>
                This page is intended to provide customers with the status of CloudCall's platform.
                When degradation of service is experienced it will be reflected on this page. If you are experiencing
                any other issues, we have lots of helpful knowledge articles on our
                support site <a rel="nofollow noreferrer" href="https://customers.cloudcall.com">https://customers.cloudcall.com</a>.
            </span>
        </div>
    );
}


export default About;
