import './App.scss';
import React, { useEffect, useState } from 'react';
import {About, Banner, ComponentGrid, Header, Incidents, Loading} from './Components';
import Box from '@mui/material/Box';

function App() {
    const [currentStatus, setCurrentStatus] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const getCurrentStatus = async function () {
        return await fetch("/status.json").then((response) => {
                if (!response.ok) {
                    // make the promise be rejected if we didn't get a 2xx response
                    const err = new Error("Not 2xx response");
                    err.response = response;
                    throw err;
                } else {
                    return response.json();
                }
            }
        ).then((response) => {
            if (!response.components) {
                setError(true);
                console.error("No components returned");
                return;
            }
            setCurrentStatus(response);
        }).then(() => {

            setTimeout(() => {
                setLoaded(true)
            }, 800)
        })
        .catch((e) => {
            setError(true);
        });
    }
    useEffect(() => {
        getCurrentStatus();
        const intervalId = setInterval(() => {
            getCurrentStatus();
        },1000 * 60);
        return () => clearInterval(intervalId);
    }, []);

  return (
    <div className="App">
        <Header />
        <div className="App-body">

            {!loaded && !error &&
                <div>
                    <Box className="containerBox">
                        <About />
                    </Box>
                    <Box className="containerBox">
                        <Loading/>
                    </Box>
                </div>
            }
            {loaded && !error &&
                <div>
                    <Box className="containerBox">
                        <Incidents incidents={currentStatus.activeIncidents}/>
                        <About />
                    </Box>
                    <Box className="containerBox">
                        <ComponentGrid components={currentStatus.components} />
                    </Box>
                </div>
            }
            {error &&
                <Box className="containerBox">
                    <Banner message="An error occurred loading latest status" alertStyle="fail"/>
                    <About />
                </Box>
            }
        </div>
    </div>
  );
}

export default App;
