const map = {
    "critical": "Critical",
    "maintenance": "In Maintenance",
    "major": "Major",
    "minor": "Minor",
    "none": "No Impact",
    "investigating": "Investigating",
    "identified" : "Issue identified",
    "monitoring" : "Monitoring",
    "emea": "EMEA",
    "apac": "APAC",
    "americas": "AMERICAS",
    "in_progress": "In Progress",
    "partial_outage": "Component is experiencing a partial outage",
    "major_outage": "Component is experiencing a major outage",
    "degraded_performance": "Component is experiencing degraded performance",
    "under_maintenance": "Component is currently undergoing maintenance",
    "operational": "Component is fully operational",
    "partial_outage-short": "Partial outage",
    "major_outage-short": "Major outage",
    "degraded_performance-short": "Degraded performance",
    "under_maintenance-short": "In Maintenance",
    "operational-short": "Operational"
}
const statusMap = {
    getStatus: function (statusName) {
        if (!map[statusName]) console.warn(`${statusName} mapping not found`);
        return map[statusName] || "";
    },
    getShortStatus: function (statusName) {
        if (!map[`${statusName}-short`]) console.warn(`${statusName} short mapping not found`);
        return map[`${statusName}-short`] || "";
    },
};

export default statusMap;
