import styles from './IncidentRow.module.scss';
import React from 'react';
import statusMap from "../StatusMap";
import IncidentDetails from "./IncidentDetails";
import { StatusIcon } from "../";
function About({ incident }) {

    return (
        <div className={styles.wrapper} data-test-id="single-incident">
            <div className={styles.iconHolder}>
                <StatusIcon style={incident.impact} />
            </div>
            <div className={`${styles.incidentDetails} ${styles[`incidentDetails-${incident.impact}`]}`}>
                <div className={styles.incidentHeader}>
                    <h2>{statusMap.getShortStatus(incident.impact)} - {incident.name}</h2>
                </div>
                <IncidentDetails incident={incident} />

            </div>
        </div>
    );
}


export default About;
