import React from 'react';
import {Cancel, Help, BuildCircle, Error, RemoveCircle, CheckCircle, CrisisAlert} from "@mui/icons-material";
import styles from "./StatusIcon.module.scss";
import { Tooltip } from "@mui/material";
import statusMap from "../StatusMap";

function StatusIcon({ style, size = 80, plain = false }) {
    if (style === undefined) return null;
    const icons = {
        critical: Cancel,
        major_outage: CrisisAlert,
        major: CrisisAlert,
        partial_outage: Error,
        minor: Error,
        degraded_performance: RemoveCircle,
        none: RemoveCircle,
        maintenance: BuildCircle,
        under_maintenance: BuildCircle,
        operational: CheckCircle,
    }
    if (style && !icons[style]) {
        console.warn(`Status ${style} not recognised by StatusIcon`);
    }
    const TargetIcon = icons[style] || Help;
    const variableStyle = plain ? styles.iconHolderPlain : `${styles[`iconHolder-${style}`]}`;

    return (
        <div className={`${styles.iconHolder} ${variableStyle}`}>
            {!style && <>-</>}
            {style &&
                <Tooltip title={statusMap.getStatus(style)}>
                    <TargetIcon sx={{fontSize: size}}/>
                </Tooltip>
            }
        </div>
    );
}


export default StatusIcon;
