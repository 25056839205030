import React from 'react';
import {Banner} from "../";
import IncidentRow from "./IncidentRow";
import MultipleIncidentCarousel from "./MultipleIncidentCarousel";

function Incidents({incidents}) {
    const incidentDisplayCount = incidents.length > 1 ? 2 : incidents.length;

    return (
        <div>
            {
                incidentDisplayCount === 0 &&
                <Banner message="All Systems Operational" alertStyle="success" />
            }
            {
                incidentDisplayCount === 1 &&
                <IncidentRow incident={incidents[0]} />
            }
            {
                incidentDisplayCount === 2 &&
                <MultipleIncidentCarousel incidents={incidents}/>
            }
        </div>
    );
}


export default Incidents;
